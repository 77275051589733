(function ($) {
  // This is for the homepage hero slideshow
  $('.cycle-slideshow').slick({
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    dots: true,
    pauseOnDotsHover: true,
  });

  // This is for the featured programs
  $('.carousel').slick({
    lazyLoad: 'ondemand',
    slidesToShow: 4,
    slidesToScroll: 4,
    adaptiveHeight: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1118,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // This is for the advisory
  var $slider = $('.advisory-slider');

  // Process the date using Moment.js as soon as the Slick Slider initializes
  $slider.on('init', function (event, slick) {
    processDates();
    // Make the slider visible after everything is ready
    $slider.show();
    $('.custom-slick-prev, .custom-slick-next').show();
  });

  // Process the date using Moment.js after slide changes as well
  $slider.on(
    'reInit afterChange',
    function (event, slick, currentSlide, nextSlide) {
      processDates();
    }
  );

  $slider.slick({
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    pauseOnHover: true,
    prevArrow: '.custom-slick-prev',
    nextArrow: '.custom-slick-next',
  });

  // Function to update the Moment.js date
  function processDates() {
    $('.advisory-slide').each(function () {
      var $this = $(this);
      var date = $this.data('date'); // Get the date from the data attribute
      var momentDate = moment(date);
      var formattedDate = momentDate.fromNow(); // Formats date in a relative manner
      $this.find('.relative-date').text(formattedDate); // Insert the formatted date
    });
  }
})(jQuery);
